<template>
  <div class="bg-container page">
    <!-- <img class="bg-img" v-if="$store.getters.getBaseInfo.login_bg" :src="$store.getters.getBaseInfo.login_bg" /> -->
    <div>
      <div class="login">
        <van-nav-bar class="nav-bar" style="background: none;padding: 20px;">
          <template #left v-if="this.$store.getters.getBaseInfo.video_login == 0">
            <van-icon name="arrow-left" color="#fff" @click="back()" />
          </template>
          <template #right>
            <div class="language" @click="$router.push('/language')">
              <img v-if="lang" :src="require('../images/language/' + lang + '.png')" />
            </div>
          </template>
        </van-nav-bar>
        <div class="wrapper">
          <div class="logo-container">
            <div class="logo-wrapper">
              <img
                class="logo-img"
                :src="
                  this.$store.getters.getBaseInfo.ico !== undefined
                    ? this.$store.getters.getBaseInfo.ico
                    : '/img/null.png'
                "
              />
            </div>
          </div>
          <!-- <div class="title">{{ this.$store.getters.getBaseInfo.name}}</div> -->
          <div class="loginForm">
            <van-field
              v-model="username"
              clearable
              class="input"
              :placeholder="$t('auth.username_place')"
            >
          <template #left-icon>
            <div style="height:100%; display: flex;justify-content: center;align-items: center;">
              <img style="width: 20px;" src="/img/login/phone.png" alt="">
            </div>
          </template>
          </van-field>
            <van-field
              v-model="password"
              :type="passwordType"
              class="input"
              :placeholder="$t('auth.pwd_place')"
            >

          <template #left-icon>
            <div style="height:100%; display: flex;justify-content: center;align-items: center;">
              <img style="width: 20px;" src="/img/login/psw.png" alt="">
            </div>
          </template>
              <template slot="right-icon">
                <van-icon
                  :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'"
                  @click="switchPasswordType"
                />
              </template>
            </van-field>
            <!-- <div class="reset-text" @click="noPsw">
              <span>{{$t("auth.forgetpwd")}}?</span>
            </div> -->
            <!-- <div @click="toRegister()" class="register-text">
              <span>{{$t("auth.no_account")}}</span>
            </div> -->
            <van-button
              class="login-btn"
              type="primary"
              size="normal"
              :disabled="isDisabled"
              @click="doLogin()"
              >{{$t("auth.login")}}</van-button
            >
            <van-button
              class="login-btn-log"
              type="primary"
              size="normal"
              :disabled="isDisabled"
              @click="toRegister()"
              >{{$t("auth.register")}}</van-button
            >
            <!-- <img :src="$store.getters.getBaseInfo.login_ad" style="width: 60%;margin: auto;margin-top: 30px;display: flex;" alt=""> -->
          </div>
        </div>
      </div>
    </div>
    <div class="floating-image" @click="toKefu" v-if="base_info.iskefu == 1">
      <img src="/img/online.png" alt="Online Support" class="image">
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "inputValue",
    event: "input",
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      username: "",
      lang:'',
      password: this.inputValue,
      passwordType: "password",
      isDisabled:false,
      base_info:{
        iskefu:0
      }
    };
  },
  mounted(){
    // localStorage.setItem("lang", 'ms_my');
    this.lang = localStorage.getItem("viodeLang") || this.$store.state.langDefault;
  },
  methods: {
    toKefu(){
      window.open(this.base_info.kefu)
    },
    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    back() {
      this.$router.push('/game')
      // return window.history.back();
    },
    noPsw() {

      this.$toast(this.$t("auth.customerService"));
    },
    toRegister() {
      this.$router.push("Register");
    },
    doLogin() {
      if (
        this.username === "" ||
        this.username === null ||
        this.username === undefined
      ) {
        this.$toast(this.$t("auth.username_place"));
        return false;
      }
      if (
        this.password === "" ||
        this.password === null ||
        this.password === undefined
      ) {
        this.$toast(this.$t("auth.pwd_place"));
        return false;
      }
      this.isDisabled = true
      this.$http({
        url: "member_login",
        method: "post",
        data: {
          username: this.username,
          password: this.password,
          lang: this.lang
        },
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg);
          localStorage.setItem("tokensso", res.data.tokensso);
          localStorage.setItem("token", res.data.id);
          this.$router.push("Mine");
        } else {
          this.$toast(res.msg);
        }
        this.isDisabled = false
      });
    },
  },
  created() {
    this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res => {
        if (res.code == 200) {
          this.base_info = res.data
        if(!localStorage.getItem('token') && res.data.video_login == 1){
          this.$router.push({path:'/Login'})
        }
        this.$store.commit('setBaseInfoValue', res.data);
        }
      })
    if (localStorage.getItem("token")) {
      return window.history.back();
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.login {
  height: 100%;
}
.bg-container .bg-wrapper .login .nav-bar {
  background: 0 0;
}

.language{
		position: absolute;
		top: 4px;
		right: 0;
		height: 80px;
		img{
			height: 100%;
		}
	}

.login .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container {
  margin: 0 auto;
  width: 60%;
}
.logo-container .logo-wrapper {
  position: relative;
  padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  opacity: 0.8;
}
.login .wrapper .title {
  line-height: 100px;
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}
.login .wrapper .loginForm {
  padding: 30px 30px 0;
}
.login .wrapper .loginForm .input {
  padding: 10px 20px;
  margin-top: 35px;
  border-radius: 10px;
  text-align: center;
  line-height: 70px;
  font-size: 30px;
  color: #4e4e4e;
}
::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.login .wrapper .loginForm .reset-text {
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.login .wrapper .loginForm .reset-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.login .wrapper .loginForm .register-text {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .wrapper .loginForm .register-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}
.login .wrapper .loginForm .active {
}
.login .wrapper .loginForm .login-btn{
  margin-top: 30px;
  width: 100%;
  height: 80px;
  border-radius: 50px;
color: #004aad;
  background: linear-gradient(90deg,#f818a2,#ffde59,#59ffa0,#59faff,#5974ff);
  font-size: 30px;
  font-weight: bolder;
  border: none;
margin-top: 60px;
}
.login-btn-log{
  margin-top: 30px;
  width: 100%;
  height: 80px;
  border-radius: 50px;
  font-size: 30px;
  font-weight: bolder;
  border: none;
margin-top: 40px;
position: relative;
background: none;
color: #004aad;
}
.login-btn-log::before {
    content: "";
    position: absolute;
    top: -6px;
    bottom: -6px;
    left: -6px;
    right: -6px;
    background: linear-gradient(90deg, #f818a2, #ffde59, #59ffa0, #59faff, #5974ff);
    border-radius: 54px;
    z-index: -2;
    transform:none;
    width: auto;
    height: auto;
    opacity: 1;
}
.login-btn-log::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 54px;
    z-index: -1;
}

.floating-image {
    position: fixed;
    bottom: 20%;
    right: 20px;
    width: 80px;
    height: 80px;
    z-index: 999 /* 如果需要，设置一个高的 z-index 以确保它出现在其他元素之上 */;
}
.floating-image .image{
  width: 80px;
    height: 80px;
}
</style>
