<template>
    <div class="container page">
        <van-nav-bar class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>

            <template #title>
                <!-- <van-icon name="arrow-left" color="#fff" @click="back()" /> -->
                {{ lottery.name }}
                <van-icon name="arrow-down" :class="{ up: title_arrow_active, down: !title_arrow_active }"
                @click="title_arrow_active ? title_arrow_active = false : title_arrow_active = true" />
            </template>
            <!--      <template #right>-->
            <!--        <div class="right">切换任务</div>-->
            <!--      </template>-->
            </van-nav-bar>

    
            <div class="item" v-for="(v, key) in lottery_list" :key="key">
                <img style="width: 60px;" :src="lottery.ico" alt="">
              <div class="left font-weight">{{ v.expect }}</div>
              <div class="right font-weight">
                <div class="kuaisan-ball left" style="justify-content: center;width: 100%;"
                  :style="{ 'margin-left': $store.getters.getBaseInfo.close_number == 1 ? '0' : '20px' }">

                  <span class="res-des middle">{{ v.one }}</span>
                  <span class="res-des middle">{{ v.two }}</span>
                </div>
              </div>
            </div>

            
      <div class="zk-nav-list" v-if="title_arrow_active">
        <ul class="list-submenu">
          <li v-for="(item, index) in gameitemList" :key="index" @click="check_game(item)"><span class="activeSpan">
            {{ item.name }}
            </span></li>
        </ul>
      </div>
    </div>
  
  </template>
  
  <script>
import { Toast } from 'vant';
  export default {
    data() {
      return {
        loading: false,
        title_arrow_active: false,
        gameitemList:[],
        lottery_list:[],
        game_id:'',
        game_key:'',
        lottery:{},
        id:''
      };
    },
    methods: {
    getLotteryList() {

      this.$http({
        method: 'get',
        data: { key: this.game_key },
        url: 'lottery_get_one_list'
      }).then(res => {
        if (res.code === 200) {
            Toast.clear();
            console.log(res.data)
          this.lottery_list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    
    check_game(item) {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true
      });
      // this.getUserInfo();
      this.game_key = item.key;
      this.game_id = item.id
      this.getLotteryInfo();
      this.getLotteryList();
      this.title_arrow_active = false;
      this.mondy = ''
    },
    
    getLotteryInfo() {
      this.$http({
        method: 'get',
        data: { key: this.game_key },
        url: 'lottery_get_info'
      }).then(res => {
        if (res.code === 200) {
          this.lottery = res.data;
          this.time = res.data.second * 1000;

          if (this.time / 1000 === 59) {
            this.$toast(this.$t("reservation.prize_succ") + this.lottery.now_expect);
          }
        } else if (res.code === 401) {
          this.$toast({
            message: res.msg,
            duration: 3000
          });
        }
      })

    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res => {
        this.gameitemList = res.data;
        this.game_key = res.data[0].key
        this.game_id = res.data[0].id
        this.getLotteryInfo();
        this.getLotteryList();
      })
    },
      back(){
        return window.history.back();
      },
    
    },
    created() {
        this.getGameItem()
    }
  };
  </script>
  
  <style lang='less' scoped>

  
.zk-nav-list {
  position: fixed;
  top: 11.733vw;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999999
}

.zk-nav-list .list-submenu {
  width: 100%;
  clear: both;
  padding: 2.667vw 0;
  overflow: scroll;
  transform: rotate(0deg)
}

.zk-nav-list .list-submenu li {
  float: left;
  width: 33.3%;
  text-align: center;
  margin-top: 1.333vw
}

.zk-nav-list .list-submenu li span {
  display: inline-block;
  width: 94%;
//   height: 8.533vw;
//   line-height: 8.533vw;
padding: 30px;
  border-radius: 1.067vw;
  color: #f5d6b7;
  background: #b47d9c
}

.zk-nav-list .list-submenu li .activeSpan {
  border: 1px solid #f5d6b7;
  font-size: 24px;
}
.van-icon {
  font-size: 40px;
}

.down {
  transition: all .5s;
  color: #ddb285;
}

.up {
  transform: rotate(180deg);
  transition: all .5s;
}


  </style>
  