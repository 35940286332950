<template>
  <div class="home-container">


    <!-- <div class="language" @click="$router.push('/language')">
      <img v-if="lang" :src="require('../images/language/' + lang + '.png')" />
    </div> -->
    <div class="mask" v-if="isLoading && video_login == 1 && !token">
      <van-loading type="spinner" size="80px" />
    </div>
    <!-- <div class="linear-bg"></div> -->
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v, key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular" />
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="notice-bar">
        <van-notice-bar class="notice-swipe" left-icon="bullhorn-o" background="#ffffff" :text="this.notice">
          <template #left-icon>
            <img style="width: 20px;margin-right: 10px;" src="/img/notice.png" alt="">
          </template>
        </van-notice-bar>
        <!-- <div class="linear-gradient"></div> -->
      </div>

      <!-- <van-grid direction="horizontal" :column-num="2" :gutter="10">
        <van-grid-item :icon="'/img/home_company.png'" :text="$t('my.jianjie')" @click="$router.push('/other')">

        </van-grid-item>
        <van-grid-item :icon="'/img/center_013.png'" :text="$t('my.download')" @click="downloadApp()" />

      </van-grid> -->
      <!-- <img src="/img/1111.png" class="exhibit"> -->
      <div class="hot-game">
        <div class="activityTitleWrap">
          <span class="icons1"></span>
          <span class="icons2"></span>
          <span class="ac-title">
            <span>{{ $t('my.rwxx') }}</span>
          </span>
          <span class="icons2"></span>
          <span class="icons1"></span>
        </div>
        <div class="hot-items-div">
          <van-grid direction="horizontal" :column-num="2" :gutter="10">
            <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
              <div style="width: 100%;">

                <van-image class="game_item_img" :src="v.ico" fit="cover">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <div class="itemWrap_right">
                  <div class="itemWrap_right_title">{{ v.name }}</div>
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </div>

      
      <div class="hot-game">
        <div class="activityTitleWrap">
          <span class="icons1"></span>
          <span class="icons2"></span>
          <span class="ac-title">
            <span>{{ $t('my.jjsy') }}</span>
          </span>
          <span class="icons2"></span>
          <span class="icons1"></span>
        </div>
        <div class="hot-items-div">
          <van-grid direction="horizontal" :column-num="2" :gutter="10">
            <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem1" :key="key">
              <div style="width: 100%;">

                <van-image class="game_item_img" :src="v.ico" fit="cover">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <div class="itemWrap_right">
                  <div class="itemWrap_right_title">{{ v.name }}</div>
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </div>

      <!-- <div class="hot-title-div">
        <div>
          <i class="service van-icon van-icon-hot-o"></i>
          <span style="margin-left: 6px;">Daftar orang-orang yang peduli</span>
        </div>
      </div> -->
      <!-- <div class="activityTitleWrap">
        <span class="icons1"></span>
        <span class="icons2"></span>
        <span class="ac-title">
          <span>{{ $t('my.gzxz') }}</span>
        </span>
        <span class="icons2"></span>
        <span class="icons1"></span>
      </div>

      <div class="hot-items-div">
        <van-grid direction="horizontal" :column-num="2" :gutter="10">
          <van-grid-item :text="$t('my.quanzhi')" @click="tofullTime(1)">
          </van-grid-item>
          <van-grid-item :text="$t('my.jianzhi')" @click="tofullTime(2)">
          </van-grid-item>
        </van-grid>
      </div> -->
      <div>

        <div class="activityTitleWrap">
          <span class="icons1"></span>
          <span class="icons2"></span>
          <span class="ac-title">
            <span>{{ $t('my.ygsr') }} </span>
          </span>
          <span class="icons2"></span>
          <span class="icons1"></span>
        </div>
        <div class="box1">
          <vue-seamless-scroll :data="listData" :class-option="classOption" class="chatList">
            <div class="profitCont" v-for="(item, index) in listData" :key="index">
              <div class="Cont_item_top">
                <div class="item_top_left">
                  <img src="/img/heade_icon.png" draggable="false" /><span><span>{{ item[0] }}</span></span>
                </div>
                <div class="item_top_right">{{ item[4] }}</div>
              </div>
              <div class="Cont_item_bott">
                <div class="item_bott_con">
                  <img :src="item[3]" draggable="false" />
                  <div class="profitText">{{ item[1] }}<span class="money"><span>{{ item[2] }}</span></span></div>
                </div>
              </div>
            </div>

          </vue-seamless-scroll>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: {
    vueSeamlessScroll
  },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  data() {
    return {

      lang: localStorage.getItem("viodeLang") || this.$store.state.langDefault,
      listData: [],
      feedback: [],
      active: 0,
      about: "",
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      gameitem1: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [],
      isLoading: true,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        // effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        // slidesPerView: 'auto',
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      },
      video_login: this.$store.getters.getBaseInfo.video_login || 1,
      token: localStorage.getItem('token')
    };
  },
  methods: {
    downloadApp() {
      if (this.$store.getters.getBaseInfo.app_url) {
        var u = navigator.userAgent;
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isiOS) {
          window.location.href = this.$store.getters.getBaseInfo.app_url;
        } else {
          window.open(this.$store.getters.getBaseInfo.app_url)
        }
      }
    },
    tofullTime(id) {
      this.$router.push({ path: '/fullTime?id=' + id, })
    },
    gotoMenu(router) {
      this.$router.replace(router)
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
        this.$router.push({ path: '/Login' })
      } else {
        if (id) {
          this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
        }
      }

    },
    toPlayVideo(id) {

      if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
        this.$router.push({ path: '/Login' })
      } else {
        if (id) {
          this.$router.push({ path: '/PlayVideo?id=' + id })
        }
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t('reservation.refresh'));
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        console.log(res.data, 222)
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getGameItem1()
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1

        this.$nextTick(() => {
          this.lazyLoadImg()
        })
      })

    },
    getNotice(data) {
      this.notice = data.notice;
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_list',
        
        data: {
          class:1
            },
      }).then(res => {
        console.log(res.data)
        this.gameitem = res.data
      })
    },
    getGameItem1() {
      this.$http({
        method: 'get',
        url: 'lottery_list',
        
        data: {
          class:2
            },
      }).then(res => {
        this.gameitem1 = res.data
      })
    },

    
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data) {
      this.movielist_1 = data.movielist_1

    },
    getBanner(data) {
      this.banners = data.banners;
    },
    query(selector) {
      return Array.from(document.querySelectorAll(selector));
    },
    lazyLoadImg() {
      if (this.movielist_1.length > 0) {
        this.query("img[data-src]").forEach((img) => {
          const rect = img.getBoundingClientRect();
          if (rect.top < window.innerHeight) {
            img.src = img.dataset.src;
            // 我们是通过img[data-src]查找所有img标签的，渲染后就删除data-src可减少forEach循环的计算成本
            img.removeAttribute("data-src");
          }
        });
      }
    }
  },
  mounted() {
    let that = this;
    window.addEventListener("scroll", () => {
      // 这里做一个 节流 操作
      that.lazyLoadImg()
    });
  },
  created() {
    this.$http({
      method: 'get',
      url: 'base_info'
    }).then(res => {
      if (res.code == 200) {
        if (!localStorage.getItem('token') && res.data.video_login == 1) {
          return this.$router.push({ path: '/Login' })
        }
        this.listData = res.data.winners
        this.feedback = res.data.feedbacklist
        this.about = res.data.about
        this.isLoading = false
        this.$store.commit('setBaseInfoValue', res.data);
        this.getBasicConfig();
      }
    })

  }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.linear-bg {
  height: 200px;
  background: linear-gradient(90deg, #93e9d0, #00bccf);
}

.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow-y: auto;
  background-color: #f4f4f4;
}

.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}

.notice-swipe {
  width: 100%;
  height: 85px;
  font-size: @notice-bar-size;
  border-radius: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
}

.notice-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  margin: 20px 40px;
}

::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}

.banner {
  width: 100%;
  // margin-top: -23%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    // border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 400px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.hot-game {
  width: 100%;
  height: 100%;
}

.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot-title-div>div:first-child {
  // width: 430px;
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 25px;
  color: #979799;
}

// .hot-title-div>div:first-child span:before {
//   content: "";
//   display: block;
//   width: 5px;
//   height: 30px;
//   background-color: #00bccf;
//   border-radius: 1px;
//   margin-right: 5px;
// }

.hot-game .hot-items-div {
  margin-top: -3px;
}

.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 28px;
  color: #000;
}

.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}

.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}

.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px
}

.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}

.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}

.movie_list_0 span {
  font-size: 30px;
}

.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.home-scroll {
  padding-bottom: 110px;
}

.movie_list_1 .movie-list-item {
  margin-bottom: 10px;
  width: 100%;
  height: 290px;
  position: relative;
}

.movie_list_1 .movie-list-item-bottom {

  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 15px;
  font-size: 16px;
  background: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), color-stop(rgba(0, 0, 0, .3)), to(#000));
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), rgba(0, 0, 0, .3), #000);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.movie_list_1 .movie-list-item:nth-child(odd) {
  margin-right: 10px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}

.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}

.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}

.hot-items-div .game_item_img {
  // width: 100px;
  // height: 100px;
  width: 100%;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  // border-radius: 20px;
  // border-radius: 50%;
  width: 100%;
  height: 300px;
  border-radius: 12px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}

::v-deep .van-tab .van-tab__text--ellipsis {
  font-size: 26px;
  line-height: 50px;
  height: 50px;
}

.box1 {
  height: 700px;
  padding: 10px 0;
  border-radius: 10px;
  // background: linear-gradient(60deg, #765e6e, #c1e2f1);
  overflow: hidden;
}

.box2 {
  flex: 1;
  flex-direction: column;
  row-gap: 10px;
  display: flex;
}

.box2 .content {
  flex: 1;
  flex-direction: column;
  row-gap: 20px;
  color: #333;
  padding: 20px;
  font-size: 22px;
  border-radius: 20px;
  background: linear-gradient(60deg, #f7f3f5, #f2f4f5);
}

.item2 {
  display: flex;
  margin-bottom: 40px;
  column-gap: 20px;
}

.chatList {
  margin-top: 10px;
  height: calc(100% - 35px);
  overflow-y: hidden;
}

.chatList .item {
  margin-bottom: 10px;
  font-size: 20px;
  color: #fff;
  line-height: 36px;
  padding: 30px;
  border-top: 1px solid #fff;
  // color: #333;
  // font-size: 28px;
  text-align: left;
}

.chatList .img {
  display: flex;
  height: max-content;
  border-radius: 100%;
  border: 1px solid #e2e2e2;
}

.home,
.body-box {
  margin: 20px;
}

.home div {
  line-height: 50px;

}

.service {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  border-radius: 50%;
  font-weight: 600;
  // background: -webkit-gradient(linear, left top, right top, from(rgba(25, 219, 250, .47)), to(#ea92cf));
  background: linear-gradient(90deg, #93e9d0, #00bccf);
  color: #fff;
}

.home_table {
  display: flex;
  padding: 10px;
}

.home_table_list {
  user-select: none;
  flex: 1;
  white-space: nowrap;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 4px;
  text-overflow: ellipsis;
  text-align: center;
}

::v-deep .van-grid-item__icon {
  // width: 36px;
  font-size: 60px;
}

::v-deep .van-grid-item__text {
  font-size: 26px;
  // width: 50%;
  width: 60%;
  color: #000;
  // margin-right: 10px;

  word-break: break-word;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  text-align: center;
  font-weight: bold;
}

::v-deep .van-grid-item__content--horizontal {
  // flex-direction: row-reverse;

}

.exhibit {
  display: block;
  margin: 20px;
  // height: 180px;
  width: calc(100% - 40px);
}

.itemWrap_right .itemWrap_right_title {
  font-size: 24px;
  text-align: center;
}

.itemWrap_right {
  // margin-right: 20px;
  text-align: left;
  position: relative;
}

.itemWrap_right .itemWrap_right_text {
  font-size: 22px !important;
  line-height: 0 !important;
}

.itemWrap_left img {
  // width: 2.75rem;
  // height: 2.75rem;
}

::v-deep .van-grid-item__content {
  border-radius: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2);

}

.profitCont .Cont_item_top .item_top_left img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 12px;
}

.profitCont .Cont_item_top .item_top_left {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 30px;
  color: #666;
}

.profitCont .Cont_item_top .item_top_right {
  color: #666;
  font-size: 22px;
}

.profitCont .Cont_item_top {
  display: flex;
  align-items: center;
  padding: 22px 32px;
  background: #fff;
  justify-content: space-between;
}

.profitCont .Cont_item_bott {
  padding: 10px 32px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profitCont .Cont_item_bott .item_bott_con {
  display: flex;
  align-items: center;
}

.profitCont .Cont_item_bott .item_bott_con img {
  width: 66px;
  height: 66px;
  border-radius: 6px;
  margin-right: 10px;
}

.profitCont .Cont_item_bott .item_bott_con .profitText {
  color: #333;
  font-size: 26px;
}

.profitCont .Cont_item_bott .item_bott_con .profitText .money {
  margin-left: 6px;
  color: #01bccf;
  font-weight: 600;
}

.language {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 80px;
  z-index: 999;

  img {
    height: 100%;
    // opacity: 0.8;
  }
}
</style>
