<template>
    <div class="container page">
      <div class="header">
        <van-nav-bar :title="id==1?$t('my.quanzhi'):$t('my.jianzhi')" class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()"/>
          </template>
        </van-nav-bar>
      </div>
      <div class="content">
        <img style="width:100%" v-if="id == 1" src="/img/parttime.jpeg" alt="">
        <img style="width:100%" v-if="id == 2" src="/img/fulltime.jpeg" alt="">
        
      </div>
    </div>
  
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        id:''
      };
    },
    methods: {
      back(){
        return window.history.back();
      },
    
    },
    created() {
        console.log(this.$route.query.id)
        this.id = this.$route.query.id
    }
  };
  </script>
  
  <style lang='less' scoped>
  </style>
  