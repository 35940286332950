<template>
  <div class="home-container">
    <div class="bd-info">
      <div class="info-model">

        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
          :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">

          <van-list v-model="loading" :finished="finished" :offset="50" :immediate-check="false"
            :finished-text="$t('video.no_more')" @load="onLoad">

            <div class="model-item" v-for="(v, key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
              <div class="item-pic">
                <!-- <img src="https://p.kieunu.live:881/g/vn_girl2/08.jpg"> -->
                <van-image fit="cover" :src="v.img" class="movie-list-n-img" :data-src="v.img" alt="" v-lazy="v.img" />
                <!-- <div class="state online"><i></i>Online</div> -->
              </div>
              <div class="item-info">
                <div class="name"><span>
                  <b>{{v.name}}</b></span></div>
                <div class="tag" v-if="v.catlist && v.catlist.length">
                  <span v-for="i in v.catlist" :key="i">{{ i }}</span>
                  
                  </div>
                  
                <!-- <div class="info"><span>{{ v.age }}Năm sinh </span><span>{{ v.attributes['46'] }}cm </span><span>Vòng ngực {{ v.attributes['51'] }}</span></div> -->
                <div class="remark"><span>{{ v.info || v.attributes['70'] }}</span></div>
                <div class="call">
                  <button class="van-button van-button--info van-button--normal van-button--round">
                    <div class="van-button__content"><span class="van-button__text">Tôi muốn
                        hẹn hò với cô ấy</span></div>
                  </button></div>
              </div>
            </div>
          </van-list>

        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      movielist_1: [],
      loading: false,
      finished: false,
      refreshing: false,
      isLoading: false,
      page: 1,
      count: 0
    };
  },
  methods: {
    gotoMenu(router) {
      this.$router.replace(router)
    },
    toPlayVideo(id) {

      if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
        this.$router.push({ path: '/Login' })
      } else {
        if (id) {
          this.$router.push({ path: '/nHomeDetail?id=' + id })
        }
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.page = 1
        // this.movielist_1 = []
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t('reservation.refresh'));
      }, 500);
    },
    onLoad() {
      this.loading = true;
      this.getBasicConfig();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.movielist_1 = [];
          this.refreshing = false;
        }
        // this.loading = false;
        if (this.movielist_1.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'nHomeList',
        data: { page: this.page },
      }).then(res => {
        console.log(res.data)
        this.loading = false;
        if (this.page == 1) {
          this.movielist_1 = res.data;
        } else {

          this.movielist_1 = this.movielist_1.concat(res.data);
        }
        if (res.data.length == 0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        this.count = res.data.count;
        this.page++;
      })

    },

    query(selector) {
      return Array.from(document.querySelectorAll(selector));
    },
    lazyLoadImg() {
      if (this.movielist_1.length > 0) {
        this.query("img[data-src]").forEach((img) => {
          const rect = img.getBoundingClientRect();
          if (rect.top < window.innerHeight) {
            img.src = img.dataset.src;
            // 我们是通过img[data-src]查找所有img标签的，渲染后就删除data-src可减少forEach循环的计算成本
            img.removeAttribute("data-src");
          }
        });
      }
    }
  },
  mounted() {
    let that = this;
    window.addEventListener("scroll", () => {
      // 这里做一个 节流 操作
      that.lazyLoadImg()
    });
  },
  created() {
    this.$http({
      method: 'get',
      url: 'base_info'
    }).then(res => {
      if (res.code == 200) {

        if (!localStorage.getItem('token') && res.data.video_login == 1) {
          return this.$router.push({ path: '/Login' })
        }
        this.isLoading = false
        this.$store.commit('setBaseInfoValue', res.data);
        this.getBasicConfig();
      }
    })
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.linear-bg {
  height: 200px;
  background: linear-gradient(90deg, #93e9d0, #00bccf);
}

.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  // background-color: #f2f2f5;
    background-color: #5731bc;
}

.movie_list_n {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  padding-bottom: 120px;
}

.movie-list-n-item {
  width: calc(50% - 11px);
  padding: 10px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  margin: 20px 5px;
  border-radius: 20px;
  padding: 10px;
}

.movie-list-n-item-bottom {}

.movie-n-time-div {
  margin-top: 20px;
}

.movie-list-n-title {
  // color: #00bccf;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
  font-weight: bold;
}

.movie-list-n-img {
  width: 330px;
  height: 330px;
  position: relative;
}

.movie-list-n-lab {
  display: inline;
  position: absolute;
  top: 15px;
  left: 15px;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  background-color: #00bccf;
  width: 315px;
  overflow: hidden;
}

.movie-list-ico-loves {
  display: inline-block;
  width: 40px;
}


.movie-list-notice {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.movie-list-ico-notice {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}

.movie-list-addr {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.movie-list-ico-addr {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}

.movie-list-money {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.movie-list-ico-money {
  width: 30px;
  display: inline-block;
  margin-right: 10px;

}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}

.bd-info .info-model .model-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background-color: #8f65fe;
  border-radius: 0.5rem;
  margin: 0.625rem;
  padding: 0.625rem;
}

.bd-info .info-model .model-item .item-pic {
  position: relative;
  width: 7.5rem;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 0.625rem;
}

.bd-info .info-model .model-item .item-info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: .75rem;
}

.bd-info .info-model .model-item .item-pic img {
  width: 7.5rem;
  height: 11.25rem;
  border: 0;
  vertical-align: middle;
}

.bd-info .info-model .model-item .item-info .name {
  margin-bottom: 0.625rem;
  margin-top: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag span {
  font-size: .75rem;
  color: #fff;
  display: inline-block;
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
  background-color: #ea92cf;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .info {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .remark {
  // height: 2.625rem;
  line-height: 1.6;
  overflow: hidden;
  margin-bottom: 0.75rem;

  display: -webkit-box;

overflow: hidden;

white-space: normal !important;

text-overflow: ellipsis;

word-wrap:break-word;

-webkit-line-clamp:3;

-webkit-box-orient: vertical
}

.bd-info .info-model .model-item .item-info .call .van-button {
  width: 100%;
  height: 2.125rem;
  border-radius: 6.1875rem !important;
}

.bd-info .info-model .model-item .item-info .remark span {
  color: #ddd2fb;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .name span {
  display: inline-block;
  color: #fff;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .info span {
  color: #ddd2fb;
  font-size: .875rem;
}

.van-button--info,
.van-button--primary {
  border: none !important;
  background: -webkit-gradient(linear, left top, right top, from(#00bccf), to(#ea92cf)) !important;
  background: linear-gradient(90deg, #93e9d0, #00bccf) !important;
  border-radius: 5px !important;
}</style>
