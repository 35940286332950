<template>
  <div class="container page">
    <van-nav-bar :title="$t('setting.language')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="items">
      <div
        class="item van-hairline--bottom"
        @click="changeLang(item.value, $event)"
        v-for="(item,index) in lang_data" :key="index"
      >
        <div class="flex_center">
          <div><img  :src="require('../images/language/' + item.value + '.png')" /></div>
          <div class="info">{{item.name}}</div>
        </div>
        <div v-if="lang == item.value">✓</div>
      </div>
    </div>
  </div>
</template>

  <script>
import { Toast } from "vant";
export default {
  name: "Language",
  data() {
    return {
      lang: this.$i18n.locale || this.$store.state.langDefault,
      source: '',
      lang_data:this.$store.getters.getBaseInfo.lang_data
    };
  },
  created() {},
  mounted() {
    this.source=this.$route.params.type
  },
  methods: {
    back(){
      return window.history.back();
    },
    changeLang(lang) {

      Toast.loading({
        // mask: true,
        duration: 200,
      });
      this.lang = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("viodeLang", lang);
      if (this.source=='setting'){
        this.$router.push({path:'/game'});
      }else{
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .items {
  background-color: #fff;
  font-size: 30px;
  color: #000;
  padding: 0 25px;
}
.container .items .item {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 10px;
}
.container .items .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.container .sign-out {
  margin: 500px 20px 0;
  height: 100px;
  line-height: 100px;
  border-radius: 50px;
  color: #fff;
  font-size: 40px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(90deg, #93e9d0, #00bccf);
}
.container .item .desc {
  font-size: 30px;
  font-weight: 700;
  color: #979799;
}
.container .item .right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex_center {
  display: flex;
  align-items: center;
}
.flex_center img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
.info{
  padding: 20px ;
}
</style>
