<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar" :title="$t('index.task')">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <!-- <div class="tabs-list">
      <div class="tabs-list-item" v-for="(item, index) in tabsList" :key="index" @click="tabsIndex = index"
        :class="{ active: tabsIndex == index }">
        {{ item.name }}
      </div>
    </div> -->
    <!-- <div class="e-title">
      <span style="color: rgb(153, 153, 153);">一</span><span
        style="margin-left: 5px; margin-right: 5px; color: rgb(51, 51, 51);">{{ $t('index.all') }}</span><span
        style="color: rgb(153, 153, 153);">一</span>
    </div> -->
    <div class="hot-items-div">
          <!-- <van-grid direction="horizontal" :column-num="2" :gutter="10">
            <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
              <div>

                <van-image class="game_item_img" :src="v.ico">
                  <template v-slot:loading>
                    <van-loading type="circular" />
                  </template>
                </van-image>
                <div class="itemWrap_right">
                  <div class="itemWrap_right_title">{{ v.name }}</div>
                </div>
              </div>
            </van-grid-item>
          </van-grid> -->

          <div class="n_game" @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
            
            <div class="n_game_t">
              <img class="n_game_img" :src="v.ico" alt="">
              <div style="flex: 1;">
                
                <div class="n_game_name">{{ v.name }}</div>
                <div class="n_game_num">
                  {{ v.next_expect }}
                </div>
              </div>
              <van-icon name="arrow" />
            </div>
            <div class="n_game_b">
              <div class="n_game_one">{{ v.one }}</div>
              <div class="n_game_one">{{ v.two }}</div>
            </div>
          </div>
    </div>
    <!-- <div class="convention-item">
    <div class="left">
      <van-sidebar @change="onChange" v-model="activeKey">
        <van-sidebar-item v-for="(v,key) in lotteryitem" :key="key" :title="v.name" />
      </van-sidebar>
    </div>
    <div class="right">
      <van-pull-refresh :border="false" class="list-wrapper" v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <van-grid :column-num="2">
          <van-grid-item @click="toLottery(v.key,v.id)" v-for="(v,key) in gameitem" :key="key">
            <van-image class="game_item_img" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
            <span>{{v.name}}</span>
            <span>{{v.desc}}</span>
          </van-grid-item>
        </van-grid>
      </van-pull-refresh>
    </div>
  </div> -->
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      gameitem: [{}, {}, {}, {}],
      lotteryitem: [{}, {}, {}, {}],
      isLoading: false,
      activeKey: 0,
      tabsList: [{
        name: this.$t('reservation.symj')
      }, {
        name: this.$t('reservation.rmmj')
      }, {
        name: this.$t('reservation.mjmd')
      }],
      tabsIndex: 0
    };
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        Toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
      }
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_list'
      }).then(res => {
        console.log(res,999)
        this.gameitem = res.data;
      })
    },
    onChange(index) {
      this.$http({
        method: 'get',
        data: { class: index },
        url: 'lottery_list'
      }).then(res => {
        this.gameitem = res.data;
      })
    },
    getLotteryItem() {
      this.$http({
        method: 'get',
        url: 'lottery_class'
      }).then(res => {
        this.lotteryitem = res.data;
      })
    },

    back() {
      window.history.back();
    },
  },
  created() {
    this.getGameItem();//获取首页游戏列表
    this.getLotteryItem();
  }
};
</script>

<style lang='less' scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}

.nav-bar {
  background: linear-gradient(90deg, #93e9d0, #00bccf);
  height: 100px;

}

.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

.van-sidebar {
  width: 180px;
}

.van-sidebar-item--select::before {
  left: 10px;
  height: 44%;
  background-color: #00bccf;
  border-radius: 5px;
  width: 10px;
}

.van-sidebar-item--select {
  color: #00bccf;
  font-size: 35px;
  text-align: center;
}

/deep/ .van-sidebar-item__text {
  width: 140px;
  margin-left: -25px;
}

.van-sidebar-item {
  font-size: 30px;
  text-align: center;
  padding: 50px;
  background-color: #ffffff;
}

.van-sidebar-item--select,
.van-sidebar-item--select:active {
  background-color: #f2f2f5;
  ;
}

.convention-item {
  display: flex;
  align-items: center;
  height: calc(100% - 15px);
}

.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}

.convention-item .left {
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.convention-item .right {
  height: 100%;
  flex: 1;
  background-color: #f2f2f5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.convention-item .right .list-wrapper {
  padding: 20px 20px;
  min-height: 800px;
}

.convention-item .right .list-wrapper .game_item_img {
  // width: 200px;
  // height: 200px;
  width: 100%;
}

.convention-item .right .list-wrapper span {
  margin-top: 10px;
  font-size: 30px;
  color: #000;
}

.convention-item .right .list-wrapper span:last-child {
  margin-top: 10px;
  font-size: 24px;
  color: #000;
}

.van-grid-item {
  padding: 10px;

}

::v-deep .van-grid-item__content--center {
  // border-radius: 15px;
}

::v-deep .van-image__img {
  border-radius: 40px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.tabs-list {
  display: flex;
  padding: 20px;
}

.tabs-list-item {
  width: 32.5%;
  margin-right: 40px;
  background: #48a7f3;
  margin: auto;
  font-size: 24px;
  text-align: center;
  padding: 20px 30px;
}

.tabs-list-item.active {
  color: #fff;
  // float: left;
  background: linear-gradient(to bottom right, #fc7051, #f79883);
}


.hot-items-div .game_item_img {
  // width: 100px;
  // height: 100px;
}
.hot-items-div{
  height:100%;
  overflow-y: auto;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  // border-radius: 20px;
  width: 100%;
  height: 240px;
  border-radius: 12px;
}
.e-title {
    line-height: 88px;
    position: relative;
    font-size: 32px;
    color: #666;
    height: 88px;
    text-align: center;
}

.itemWrap_right .itemWrap_right_title {
  font-size: 24px;
  text-align: center;
}

.itemWrap_right {
  // margin-right: 20px;
  text-align: center;
  position: relative;
}

.itemWrap_right .itemWrap_right_text {
  font-size: 22px !important;
  line-height: 0 !important;
}

.n_game{
  padding: 20px 30px;
  background: #fff;
  border-radius: 10px;
  margin: 30px ;
}

.n_game_img{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.n_game_name{
  margin-bottom: 20px;
}

.n_game_num{
  font-size: 24px;
  color: #999;
}

.n_game_one{
  margin: 0 20px;
  color: #e9bc18;
}

.n_game_t{
  display: flex;
  // justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  // justify-content: space-between;
}
.n_game_b{

  display: flex;
  // justify-content: center;
  align-items: center;
}
</style>
