<template>
  <div class="container page business_listWrap">

    <div class="top_wrap">
      
    <van-nav-bar :title="this.lottery.name" class="nav-bar"
      style="background: transparent">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <!--      <template #right>-->
      <!--        <div class="right">切换任务</div>-->
      <!--      </template>-->
    </van-nav-bar>
      <div class="top_wrap_item1">
        <div class="pipei_new">
          <div class="pipei_new_l">


            <div style="margin-bottom: 10px;">
              【{{ this.lottery.next_expect }}】
            </div>
            <div>
              {{ $t('dianyin.pipeijieguo') }}
            </div>

          </div>
          <div class="pipei_new_r"><van-loading type="spinner" color="#fff" style="margin-right: 5px;" />{{ $t('dianyin.zhengzaipipei') }}</div>
        </div>
        <div class="pipei_old">
          <div class="pipei_old_l">
            <div style="margin-bottom: 10px;">
              【{{ this.lottery.now_expect }}】
            </div>

            <div>

              {{ $t('dianyin.goupiaojiezhi') }}
            </div>

          </div>
          <div class="pipei_old_r">
            <div style="margin-right: 6px;">

              {{ $t('dianyin.piaodanpipei') }}
            </div>
            <van-count-down :time="time" @finish="check()" format="mm:ss">
              <template #default="timeData">
                <!-- <span class="block">{{ timeData.hours }}</span>
              <span class="colon">:</span> -->
                <span class="block">{{ timeData.minutes > 10 ? timeData.minutes : '0' + timeData.minutes }}</span>
                <span class="colon">:</span>
                <span class="block">{{ timeData.seconds > 10 ? timeData.seconds : '0' + timeData.seconds  }}</span>
              </template>

            </van-count-down>
          </div>
        </div>
        <!-- <div class="wrap_div">
          <p class="issuce">
            <span class="qihao_number">{{ this.lottery.next_expect }}</span>
            <span class="qihao_number-2">
              {{ $t('reservation.public_end') }}</span>
          </p>
          <p class="issuce" v-if="time !== null" style="display: flex;justify-content: center;">

            <van-count-down :time="time" @finish="check()" format="mm:ss" />
          </p>
        </div> -->

        <!-- <div class="wrap_div">
          <p class="issuce">
            <span class="qihao_number-2">{{ $t('reservation.public_p_results') }}</span>
          </p>
          <p class="issuce">
              <span class="qihao_number-3"> {{this.size}} - {{this.double}}</span>
          </p>
        </div> -->
      </div>
    </div>
    <div class="wrapper" :class="isiOS ? 'isiOS' : ''">
      <div class="options-bar">
        <div class="game">
          <div class="linear-gradient"
            style="background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));">
          </div>
          <div class="list_cont_title">
            <div class="cont_title_item" :class="{ active: choose[v.type] }" v-for="(v, key) in lottery_peilv_list"
              :key="key" @click="choosePlay(v.type, v.name);">
              {{ v.name }}</div>
          </div>
          <!-- <div class="sumValueTwoSides">
            <div class="rectangle large" style="position: relative;" :class="{ active: choose[v.type] }"
              v-for="(v, key) in lottery_peilv_list" :key="key" @click="choosePlay(v.type, v.name);">
              {{ v.name }}
            </div>
          </div> -->

          <div class="sumValueTwoSides">
            <div class="rectangle large" style="position: relative;" :class="{ active: v.isckecked }"
              @click="v.isckecked = !v.isckecked" v-for="(v, key) in pic_list" :key="key">
              <img class="wrapper_img" :src="v.img" alt="">
            </div>
          </div>
          <div>
            <div class="activityTitleWrap">
              <span class="icons1"></span>
              <span class="icons2"></span>
              <span class="ac-title">
                <span>{{ $t('my.ygsr') }}</span>
              </span>
              <span class="icons2"></span>
              <span class="icons1"></span>
            </div>
            <div class="box1">
              <vue-seamless-scroll :data="listData" :class-option="classOption" class="chatList">

                <div class="profitCont" v-for="(item, index) in listData" :key="index">
                  <div class="Cont_item_top">
                    <div class="item_top_left">
                      <img src="/img/heade_icon.png" draggable="false" /><span><span>{{ item[0] }}</span></span>
                    </div>
                    <div class="item_top_right">{{ item[4] }}</div>
                  </div>
                  <div class="Cont_item_bott">
                    <div class="item_bott_con">
                      <img :src="item[3]" draggable="false" />
                      <div class="profitText">{{ item[1] }}<span class="money"><span>{{ item[2] }}</span></span></div>
                    </div>
                  </div>
                </div>

              </vue-seamless-scroll>
            </div>
          </div>
        </div>

      </div>
      <div class="bottom-bar">
        <div class="bar">
          <div class="left">
            <div class="item" @click="shopping ? shopping = false : shopping = true">
              <van-icon name="delete-o" class="jixuanico" />
              <!-- <span class="text">{{ $t("reservation.task_list") }}</span> -->
            </div>
            <div class="line"></div>
          </div>
          <div class="mid">
            <div><span class="text">{{ $t("reservation.available_balance") }}</span></div>
            <div><span class="text num">{{ this.userInfo.money }}</span>
              <span class="text">{{ $t("reservation.unit") }}</span>
            </div>

          </div>
          <div class="right" @click="dalShow = true">
            {{ $t("reservation.submit") }}
          </div>
        </div>
        <div class="wrapper" :class="{ active: shopping }">
          <div class="item" style="display: flex;">
            <span class="label">{{ $t("reservation.curr_choose") }}：</span>
            <div class="bet-number">{{ this.shopchoose }}</div>
            <van-icon name="arrow-down" :class="{ up: !shopping, down: shopping }"
              @click="shopping ? shopping = false : shopping = true" />
          </div>
          <div class="item">
            <!-- <div class="amount-wrapper">
              {{ $t("reservation.available_balance") }}: <span style="color: red;margin: 0 4px;"> {{ this.userInfo.money
                }}
              </span>{{ $t("reservation.unit") }}
            </div> -->
            <div class="amount-wrapper" style="display: flex;align-items: center;">

              <span style="margin-right: 10px;">{{ $t("reservation.per_price") }}:</span>
              <!-- <van-stepper v-model="money" integer /> -->

              <van-field v-model="money" type="digit" :placeholder="$t('reservation.price_place')" />
            </div>
            <div class="amount-wrapper">
              <span style="color: #999;">

                {{ $t('dianyin.gong') }} {{ formData.length }} {{ $t('dianyin.jian') }}
              </span>
              <span style="margin-left: 10px;">

                {{ $t('dianyin.heji') }}：<span style="color: red;">{{ getTotal }}</span> {{ $t("reservation.unit") }}
              </span>
            </div>

            <!-- <div style="margin-left:auto;font-size: 14px;">{{$t('my.balance')}}：{{ this.userInfo.money }}</div> -->
          </div>
          <!-- <div class="item">
            <div class="part">
              <span>{{ $t("reservation.total") }}</span>
              <span class="number">{{ this.formData.length }}</span>
              <span>{{ $t("reservation.note") }}</span>
            </div>
            <div class="part">
              <span>{{ $t("reservation.total") }}</span>
              <span class="number">{{ this.formData.length * this.money }}</span>
              <span>{{ $t("reservation.unit") }}</span>
            </div>

          </div> -->
        </div>
      </div>
      <van-popup v-model="jiesuanpage" get-container="body">
        <div class="confirm-order-modal">
          <div class="head van-hairline--bottom">
            <p class="text">{{ $t("reservation.task_list") }}</p>
          </div>
          <ui class="list">
            <li v-for="(v, key) in formData" :key="key" class="lise-item van-hairline--bottom">
              <div class="main">
                <p class="bet-name">{{ v.name }}</p>
                <p class="detail-text">1{{ $t("reservation.note") }}X{{ money }}{{ $t("reservation.unit") }}={{ money
                  }}{{ $t("reservation.unit") }}</p>
              </div>
              <van-icon @click="clearChooes(v.type)" name="close" />
            </li>
          </ui>
          <div class="sub-bar">
            <van-button class="item cancel-btn" type="default" @click="allClear()">{{ $t("reservation.clear_order")
              }}</van-button>
            <van-button class="item sub-btn" :disabled="isDisabled" type="default" @click="doSub">{{
              $t("reservation.submit") }}</van-button>
          </div>
        </div>
      </van-popup>
      <!-- <van-popup v-model="active" position="top" :style="{ height: '70%' }" >
              <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
                  <div class="wrapper">
                    <div class="item">
                      <div class="left font-weight">{{$t("reservation.num")}}</div>
                      <div class="right font-weight" >{{$t("reservation.win_num")}}</div>
                    </div>
                    <div class="item" v-for="(v,key) in lottery_list" :key="key">
                      <div class="left font-weight">{{v.expect}}</div>
                      <div class="right font-weight" >
                        <div class="kuaisan-ball left" style="justify-content: center;width: 100%;" :style="{'margin-left':$store.getters.getBaseInfo.close_number==1?'40%':'6px'}">
                          <template v-if="$store.getters.getBaseInfo.close_number==0">
                            <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[0] + '.png?v=3'">
                            <template v-slot:loading>
                              <van-loading type="spinner"/>
                            </template>
                          </van-image>
                          <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[1] + '.png?v=3'">
                            <template v-slot:loading>
                              <van-loading type="spinner"/>
                            </template>
                          </van-image>
                          <van-image class="res-img" :src="'img/lottery/shaizi/' + v.opencode[2] + '.png?v=3'">
                            <template v-slot:loading>
                              <van-loading type="spinner"/>
                            </template>
                          </van-image>
                          <span class="res-des middle">{{v.opencode[0] + v.opencode[1] + v.opencode[2]}}</span>
                          </template>
                            <div>

                              <div class="res-des middle lotteryOne">{{v.one}}</div>
                                                      <div class="res-des middle lotteryOne">{{v.two}}</div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </van-pull-refresh>
        </van-popup> -->
    </div>
    <van-dialog @confirm="jiesuan" v-model="dalShow" style="width: 70%;font-size: 20px;"
      :cancelButtonText="$t('my.cancel')" :confirmButtonText="$t('my.confirm')" show-cancel-button>
      <template #title>{{ $t("reservation.czsm") }}</template>
      {{ $t("reservation.dy") }} <span style="color:red"> {{ this.lottery.next_expect }} </span>{{ $t("reservation.sjtc") }}
    </van-dialog>
  </div>
</template>

<script>
// var time;
// var count = 0;
var u = navigator.userAgent;

var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  data() {
    return {
      dalShow: false,
      isiOS: isiOS,
      jiesuanpage: false,
      listData: [],
      choose: {
        "bi": false,
        "sm": false,
        "si": false,
        "do": false,
        "3": false,
        "4": false,
        "5": false,
        "6": false,
        "7": false,
        "8": false,
        "9": false,
        "10": false,
        "11": false,
        "12": false,
        "13": false,
        "14": false,
        "15": false,
        "16": false,
        "17": false,
        "18": false,
      },
      playgame: false,
      shopping: false,
      isLoading: false,
      play: {},
      lottery_peilv_list: {},
      lottery_list: {},
      active: false,
      userInfo: {},
      lottery: {},
      shanzi_1: "0",
      shanzi_2: "0",
      shanzi_3: "0",
      sum: 0,
      size: "",
      double: "",
      time: null,
      shopchoose: this.$t("reservation.no_choose"),
      gameitem: "",
      formData: [],
      money: "",
      isDisabled: false,
      pic_list: []
    };
  },
  computed: {
    getTotal() {

      if (this.formData.length > 0 && this.money > 0) return this.formData.length * this.money

      return 0
    },
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  components: {
    vueSeamlessScroll
  },
  methods: {


    back() {
      this.$router.go(-1)
      // return window.history.back();
    },
    doSub() {
      if (this.money === "0") {
        this.$toast(this.$t("reservation.money_err"));
        return false;
      }
      if (this.formData.length === 0) {
        this.$toast(this.$t("reservation.choose_num"));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t("reservation.price_place"));
        return false;
      } else {
        if (this.userInfo.money - (this.money * this.formData.length) < 0) {
          this.$toast(this.$t("reservation.balance_enough"));
          return false;
        } else {
          if (this.isDisabled) return
          this.isDisabled = true
          this.$http({
            method: 'post',
            data: {
              item: this.gameitem,
              money: this.money,
              lid: this.lottery.id,
              mid: this.userInfo.id,
              expect: this.lottery.now_expect
            },
            url: 'game_place_order'
          }).then(res => {
            if (res.code === 200) {
              this.$toast(res.msg);
              this.allClear();
              this.getUserInfo();
            } else if (res.code === 401) {
              this.$toast(res.msg);
            }
            this.isDisabled = false
          })
          return true;
        }
      }
    },
    allClear() {
      for (var i = 0; i < this.formData.length; i++) {
        this.choose[this.formData[i]['type']] = false;
      }
      this.formData.length = 0;
      this.money = "";
      this.shopchoose = this.$t("reservation.no_choose");
      this.gameitem = "";
      this.shopping = false;
      this.jiesuanpage = false;
    },
    jiesuan() {

      if (this.formData.length === 0) {
        this.$toast(this.$t("reservation.choose_num"));
        return false;
      } else if (this.money === "") {
        this.$toast(this.$t("reservation.price_place"));
        return false;
      }
      else {
        this.jiesuanpage ? this.jiesuanpage = false : this.jiesuanpage = true;
      }

    },
    clearChooes(type) {
      for (var i = 0; i < this.formData.length; i++) {
        if (type === this.formData[i]['type']) {
          this.formData.splice(i, 1)
          this.choose[type] = false;
        }
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.formData[j]['name'];
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.formData[j]['name'];
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = this.$t("reservation.no_choose");
        this.gameitem = "";
        this.shopping = false;
      }
      if (this.formData.length === 0) {
        this.jiesuanpage = false;
      }
    },
    choosePlay(type, name) {
      if (this.choose[type] === true) {
        this.choose[type] = false;
        for (var i = 0; i < this.formData.length; i++) {
          if (type === this.formData[i]['type']) {
            this.formData.splice(i, 1)
          }
        }
      } else if (this.choose[type] === false) {
        this.formData.push({ 'name': name, 'type': type })
        this.choose[type] = true;
      }
      if (this.formData.length === 1) {
        this.shopping = true;
      }
      if (this.formData.length >= 1) {
        for (var j = 0; j < this.formData.length; j++) {
          if (j === 0) {
            this.shopchoose = this.formData[j]['name'];
            this.gameitem = this.formData[j]['type'];
          } else {
            this.shopchoose += "," + this.formData[j]['name'];
            this.gameitem += "," + this.formData[j]['type'];
          }
        }
      } else {
        this.shopchoose = this.$t("reservation.no_choose");
        this.gameitem = "";
        this.shopping = false;
      }

    },
    check() {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        // time = window.setInterval(() => {
        //   setTimeout(()=>{
        //     count++;
        //     if(count > 5){
        //       clearInterval(time);
        //       count = 0;
        //     }
        //   },0)
        // }, 300)

        this.getUserInfo();
        this.getLotteryInfo();
        this.getLotteryList();
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.getLotteryList();
        this.isLoading = false;
      }, 200);
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryPeilv() {
      this.$http({
        method: 'get',
        data: { id: this.$route.query.id },
        url: 'lottery_get_peilv'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_peilv_list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryList() {
      this.$http({
        method: 'get',
        data: { key: this.$route.query.key },
        url: 'lottery_get_one_list'
      }).then(res => {
        if (res.code === 200) {
          this.lottery_list = res.data;
          this.getLotteryPeilv();
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
    getLotteryInfo() {
      this.$http({
        method: 'get',
        data: { key: this.$route.query.key },
        url: 'lottery_get_info'
      }).then(res => {
        if (res.code === 200) {
          // if(parseFloat(this.userInfo.money) < parseFloat(res.data.condition)){
          //   this.$toast(this.$t("reservation.contact_admin"));
          //   this.$router.push({path:'/Home'})
          //   return false;
          // }
          this.lottery = res.data;
          this.pic_list = res.data.pic_list.map(item => {
            return {
              img: item,
              isckecked: false
            }
          })
          console.log(this.pic_list)
          this.time = res.data.second * 1000;
          console.log(this.lottery, 888)
          if (this.time / 1000 === 59) {
            this.$toast(this.$t("reservation.prize_succ") + this.lottery.now_expect);
          }
          if (res.data?.opencode && res.data?.opencode.length > 0) {

            this.shanzi_1 = "img/lottery/shaizi/" + res.data?.opencode[0] + ".png?v=3";
            this.shanzi_2 = "img/lottery/shaizi/" + res.data?.opencode[1] + ".png?v=3";
            this.shanzi_3 = "img/lottery/shaizi/" + res.data?.opencode[2] + ".png?v=3";
            this.sum = res.data.opencode[0] + res.data.opencode[1] + res.data.opencode[2];
          }
          // if (this.sum >= 11 && this.sum <= 18) {
          //   this.size = 'A';
          // } else if (this.sum >= 3 && this.sum <= 10) {
          //   this.size = 'B';
          // }
          // if (this.sum % 2 === 0) {
          //   this.double = 'D';
          // } else {
          //   this.double = 'C';
          // }
          this.size = res.data.one;
          this.double = res.data.two;
        } else if (res.code === 401) {
          this.$toast({
            message: res.msg,
            duration: 3000
          });
        }
      })

    }
  },
  created() {
    console.log(this.isiOS, u)

    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {

      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res => {
        if (res.code == 200) {
          this.listData = res.data.winners
        }
      })
      this.getUserInfo();
      this.getLotteryInfo();
      this.getLotteryList();
    }
  },
  destroyed() {
    // clearInterval(time);
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.nav-bar .right {
  padding-left: 8px;
  padding-right: 8px;
  color: #fff;
  font-size: 28px;
  border-radius: 10px;
  border: 2px solid #fff;
  line-height: 60px;
}

.record {
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 #cacaca;
  z-index: 1;
}

.record .period {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.record .period .cover {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}

.record .period .period-number {
  flex: 1;
  margin-left: 20px;
  margin-right: 10px;
  height: 50px;
  line-height: 50px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}

.van-count-down {
  color: #ff253f;
  font-size: 26px;
  margin-top: 10px;
  font-weight: bold;
}

.linear-gradient {
  width: 100%;
  height: 2px;
}

.record .recent {
  display: flex;
  align-items: center;
  height: 110px;
}

.kuaisan-ball .left {
  justify-content: flex-start;
}

.kuaisan-ball {
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img {
  width: 70px;
  height: 70px;
  margin-right: 30px;
}

.kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.kuaisan-ball .res-des.middle {
  width: max-content;
  font-size: 35px;
  margin-right: 20px;
}

.van-icon {
  font-size: 40px;
}

.down {
  transition: all .5s;
}

.up {
  transform: rotate(180deg);
  transition: all .5s;
}

.wrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.options-bar {
  display: flex;
  align-items: center;
  height: calc(100% - 80px);
}

.options-bar .game {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.options-bar .game .tips {
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0 20px;
}

.options-bar .game .tips .odds {
  flex: 1;
  font-size: 35px;
  font-weight: 500;
  color: #ff253f;
}

.options-bar .game .tips .play-tip {
  display: flex;
  align-items: center;
  height: 100%;
}

::v-deep .van-icon-more-o {
  color: #ff253f;
  font-size: 50px;
}

.options-bar .game .tips .play-tip .span-text {
  margin-left: 10px;
  font-size: 35px;
  font-weight: bolder;
  color: #ff253f;
}

.linear-gradient {
  width: 100%;
  height: 2px;
}

.sumValueTwoSides {
  display: flex;
  padding: 30px 20px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.rectangle {
  overflow: hidden;
}

.rectangle.large {
  // margin: 0 0 30px 4%;
  width: 170px;
  height: 170px;
  margin: 0 auto 0px;
  display: flex;
  justify-content: center;
  // height: 340px;
  border-radius: 10px;
}

.rectangle .wrapper {
  position: relative;
  padding: 0 10px;
  background: #fff;
}

.rectangle .wrapper .content {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.rectangle.large .wrapper {
  // padding-bottom: 100%;
}

.rectangle .wrapper .content .name-text.large {
  font-size: 30px;
}

.rectangle .wrapper .content .name-text {
  color: #7d7c7c;
  font-weight: bolder;
}

.rectangle .wrapper .content .odd-text.large {
  font-size: 25px;
  margin-top: -30px;
}

.rectangle .wrapper .content .odd-text {
  text-align: center;
  color: #ff253f;
}

.bottom-bar {
  position: relative;
  left: 0;
  right: 0;
  bottom: 10px;
  height: 100px;
  z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-bar .bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 #cacaca;
  z-index: 2;
}

.bottom-bar .bar .left,
.bottom-bar .bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottom-bar .bar .left .item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 160px;
  font-size: 20px;
}

.bottom-bar .bar .left .item .text {
  font-size: 22px;
  color: #7d7c7c;
}

.jixuanico {
  font-size: 30px;
}

.bottom-bar .bar .left .line {
  width: 2px;
  height: 50px;
  background: #dadada;
}

.bottom-bar .bar .mid {
  margin-left: 10px;
  flex: 1;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
}

.bottom-bar .bar .mid .text {
  font-size: 30px;
  font-weight: 500;
  color: #000;
}

.bottom-bar .bar .mid .text.num {
  margin: 0 5px;
  color: #ff253f;
}

.bottom-bar .bar .right {
  padding: 0 30px;
  margin-left: 30px;
  color: #fff;
  background:linear-gradient(90deg, #f80201, #fcc205);
  font-size: 32px;
  font-weight: 500;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // line-height: 70px;
  // border-radius: 50px;
}

.rectangle.active .wrapper {
  background-color: #ff253f !important;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-popup {
  position: absolute;
}

::v-deep .van-overlay {
  position: absolute;
  background-color: rgb(70 67 67 / 70%);
}

::v-deep .van-popup--top {
  top: -1px;
}

.wrapper .item {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  padding: 10px 0;
}

.wrapper .item .left {
  width: 35%;
  font-size: 30px;
  text-align: center;
  font-weight: 500;
  color: #000;
}

.font-weight {
  font-weight: 700 !important;
}

.wrapper .item .right {
  flex: 1;
  display: flex;
  font-size: 30px;
  justify-content: center;
  overflow: hidden;
  color: #000;
}

.wrapper .item .kuaisan-ball .left {
  justify-content: flex-start;
}

.wrapper .item .kuaisan-ball {
  margin-left: 20px;
  flex: 1;
  display: flex;
  align-items: center;
}

.wrapper .item .kuaisan-ball .res-img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.wrapper .item .kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}

.wrapper .item .kuaisan-ball .res-des.middle {
  width: max-content;
  font-size: 35px;
  margin-right: 20px;
}

.lotteryOne {
  font-size: 28px !important;
  margin-right: 0 !important;
  // margin-left: 10px;
  margin: auto;
}

.play-type-tip {
  position: unset;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 650px;
  height: 700px;
  max-height: 50%;
  z-index: 10;
  border-radius: 30px;
  overflow: hidden;
  background-color: #fff;
  color: #000;
}

.play-type-tip .title {
  line-height: 90px;
  background: linear-gradient(90deg, #00bccf, #ea92cf);
  text-align: center;
  color: #fff;
  font-size: 35px;
  font-weight: 500;
}

.mask {
  background-color: rgb(0 0 0 / 0%);
  animation-duration: 0.35s;
}

.play-type-tip .wrapper {
  height: calc(100% - 10px);
  background-color: transparent;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.play-type-tip .wrapper .item {
  padding: 40px 50px;
  // display: flex;
  // align-items: flex-start;
}

.play-type-tip .wrapper .item .van-icon {
  color: #ea92cf;
  font-size: 60px;
}

.play-type-tip .wrapper .item .content .content-title {
  margin-top: 22px;
  font-size: 35px;
  font-weight: 500;
  color: #000;
  line-height: 0px;
}

.play-type-tip .wrapper .item .content .content-detail {
  margin-top: 5px;
  font-size: 22px;
  color: #000;
  line-height: 30px;
}

.play-type-tip .wrapper .item .content {
  flex: 1;
  margin-left: 30px;
}

.rectangle.active .wrapper {
  background-color: #ff253f !important;
}

.rectangle.active .wrapper .name-text,
.rectangle.active .wrapper .odd-text {
  color: #fff !important;
}

.bottom-bar .wrapper {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  padding: 20px 20px 10px 20px;
  // height: 230px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 10px 0 #cacaca;
  transition: transform .3s cubic-bezier(.21, 1.02, .55, 1.01);
}

.bottom-bar .wrapper.active {
  transform: translateY(-100%);
}

.bottom-bar .wrapper .item {
  position: relative;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // height: 65px;
}

.bottom-bar .wrapper .item .label {
  font-size: 30px;
  line-height: 30px;
  color: #000;
}

.bottom-bar .wrapper .item .bet-number {
  flex: 1;
  margin: 0 16px;
  overflow: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  color: #ff253f;
  font-size: 30px;
  font-weight: 500;
  // height: 40px;
  line-height: 40px;
}

.bottom-bar .wrapper .item .amount-wrapper {
  // flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 28px;
  margin-bottom: 20px;
}

.van-cell {
  font-size: 30px;
  line-height: 50px;
  width: 50%;
}

.bottom-bar .wrapper .item .part {
  margin-right: 20px;
}

.bottom-bar .wrapper .item .part span {
  font-size: 30px;
  vertical-align: center;
  color: #000;
}

.bottom-bar .wrapper .item .part .number {
  margin: 0 5px;
  color: #ff253f;
  font-weight: 500;
}

::v-deep .van-field__control {
  color: #ff253f;
}

.confirm-order-modal {
  position: unset;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 20px 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 610px;
  height: 680px;
  max-height: 50%;
  z-index: 10;
  background-color: #fff;
  border-radius: 30px;
}

.confirm-order-modal .head {
  position: relative;
  height: 80px;
}

.confirm-order-modal .head .text {
  padding: 0 20px;
  height: 30px;
  line-height: 10px;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  color: #00bccf;
}

::v-deep .confirm-order-modal .van-hairline--bottom::after {
  border-bottom-width: 2px;
}

.van-popup--center {
  border-radius: 30px;
}

.confirm-order-modal .list {
  flex: 1;
  padding: 0 10px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.confirm-order-modal .list .lise-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
}

.confirm-order-modal .list .lise-item .main {
  flex: 1;
  overflow: hidden;
}

.confirm-order-modal .list .lise-item .main .bet-name {
  color: #ff253f;
  font-size: 35px;
  font-weight: 500;
  line-height: 0px;
  word-wrap: break-word;
  word-break: break-all;
}

.confirm-order-modal .list .lise-item .main .detail-text {
  // line-height: 0px;
  font-size: 25px;
  color: #979799;
}

.confirm-order-modal .list .lise-item {
  color: #ff253f;
}

.confirm-order-modal .sub-bar {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-around;
}

.confirm-order-modal .sub-bar .item {
  min-width: 40%;
  height: 80px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 35px;
  font-weight: 500;
}

.confirm-order-modal .sub-bar .item.cancel-btn {
  border: 2px solid #979799;
  color: #979799;
  background-color: #fff;
}

.confirm-order-modal .sub-bar .item.sub-btn {
  background: linear-gradient(90deg, #f80201, #fcc205);
  color: #fff;
  border: 0;
}

.next-number span {
  font-size: 35px;
  font-weight: 700;
  color: #000;
  float: right;
}

.isiOS {
  // flex: 1;
  // height: 100%;
}

.mask {
  background-color: rgb(0 0 0 / 0%);
  animation-duration: 0.35s;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.wrapper_img {
  width: 90%;
  height: 90%;
  margin: auto;
  border-radius: 50%;
}

.active .wrapper_img {
  border: 5px solid #e6241a;
}


.list_cont_title {
  padding: 30px 30px 0;
  display: flex;
  align-items: center;
}

.cont_title_item {
  flex: 1;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  border-radius: 10px;
  font-size: 22px;
  background: #fff;
  color: #333;
  text-align: center;
}

.cont_title_item:last-child {
  margin-right: 0;
}

.cont_title_item.active {
  background: #e6241a;
  color: #fff;
}


.box1 {
  max-height: 700px;
  padding: 10px 0;
  border-radius: 10px;
  // background: linear-gradient(60deg, #765e6e, #c1e2f1);
  overflow: hidden;
}

.business_listWrap .top_wrap {
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0 0 22px 0 rgba(0, 0, 0, .1);
  background-image: url('/img/bg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // margin-bottom: 22px;
}

.business_listWrap .top_wrap .top_wrap_item1 {
  // display: flex;
  // align-items: center;
  margin-bottom: 22px;
}

.wrap_div {
  border-right: 2px solid #ddd;
  float: left;
  width: 50%;
  font-size: 26px;
  text-align: center;
}

.issuce {
  padding: 0;
  margin: 10px 0 0 0;
}

.qihao_number {
  color: #d80b2a;
  font-size: 24px;
}

.qihao_number-2 {
  font-size: 20px;
  margin-left: 10px;
}

::v-deep .van-button__text {
  font-size: 30px;
}

::v-deep .van-dialog__cancel,
::v-deep .van-dialog__confirm,
::v-deep .van-dialog__header {
  height: 80px;
  line-height: 80px;
  font-size: 28px;
}

::v-deep .van-dialog__content {
  padding: 30px;
  font-size: 28px;
}


.profitCont .Cont_item_top .item_top_left img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 12px;
}

.profitCont .Cont_item_top .item_top_left {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 30px;
  color: #666;
}

.profitCont .Cont_item_top .item_top_right {
  color: #666;
  font-size: 22px;
}

.profitCont .Cont_item_top {
  display: flex;
  align-items: center;
  padding: 22px 32px;
  background: #fff;
  justify-content: space-between;
}

.profitCont .Cont_item_bott {
  padding: 10px 32px;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profitCont .Cont_item_bott .item_bott_con {
  display: flex;
  align-items: center;
}

.profitCont .Cont_item_bott .item_bott_con img {
  width: 66px;
  height: 66px;
  border-radius: 6px;
  margin-right: 10px;
}

.profitCont .Cont_item_bott .item_bott_con .profitText {
  color: #333;
  font-size: 26px;
}

.profitCont .Cont_item_bott .item_bott_con .profitText .money {
  margin-left: 6px;
  color: #01bccf;
  font-weight: 600;
}

.top_wrap_item1 {
  padding: 30px;
  color: #fff;
}

.pipei_new {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.pipei_new_l {
  background: linear-gradient(90deg, #f80201, #fcc205);
  color: #fff;
  border-radius: 20px;
  font-size: 24px;
  padding: 20px 30px;
  text-align: center;
}

.pipei_new_r {

  justify-content: center;
  display: flex;
  align-items: center;
}

.pipei_old {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
}

.pipei_old_l {}


.colon {
  display: inline-block;
  margin: 0 8px;
  color: #ee0a24;
}

.pipei_old_r {

  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  display: inline-block;
  // width: 44px;
  color: #fff;
  font-size: 32px;
  text-align: center;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  background-color: #ee0a24;
  border: 1px solid #f5f5f5;
}

.van-count-down {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

}
</style>
