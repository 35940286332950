<template>
    <div class="movie-hall page">

        <van-nav-bar :title="$t('foorter.event')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>

        <div class="activityWrap" v-if="list.length > 0">

            <div class="listItem" v-for="(item,index) in list" :key="index">
                <div class="item_img">
                    <img :src="item.image" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            list:[]
        };
    },
    methods: {
        back() {
            this.$router.push({ path: 'Home' })
        },
    },
    created() {

      this.$http({
        method: 'get',
        url: 'getlvlist'
      }).then(res=>{
        this.list = res.data
      })
    }
};
</script>
  
<style lang='less' scoped>
.activityWrap .listItem {
    padding: 16px;
}
.activityWrap .listItem .item_img {
    width: 100%;
    // height: 308px;
}
.activityWrap .listItem .item_img img {
    width: 100%;
    height: 100%;
    border-radius: 22px;
    // margin-bottom: 30px;
}
</style>
  