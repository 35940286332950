<template>
    <div class="container page">
        <van-nav-bar :title="this.$t('about.title')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div class="vHtml">
            <div v-html="content"></div>
        </div>
        
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            content: this.$t('about.us'),
        };
    },
    methods: {
        back() {
            return window.history.back();
        },
    },
    created() {

    }
};
</script>
  
<style lang='less' scoped>.vHtml {
    font-size: 32px;
    padding: 0 30px;
}</style>
  