<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#00bccf"
    :border="true"
    inactive-color="#979799"
  >
  <!-- 首页 -->
    <van-tabbar-item :to="item.path" v-for="(item,index) in footerList" :key="index">
      <span class="foorterTxt">{{ item.name }}</span>
      <template #icon="props">
        <img
          :src="props.active ? item.activeImg : item.img"
          :alt="$t('foorter.index')"
        />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0,
      footerList:[{
        path:'/home',
        name:this.$t("foorter.index") ,
        activeImg:'/img/footer/dbsyh-active.png?d='+new Date()*1,
        img:'/img/footer/dbsyh.png?d='+new Date()*1
      },

      {
        path:'/GameRecord',
        name:this.$t("foorter.message") ,
        activeImg:'/img/footer/dbddh-active.png?d='+new Date()*1,
        img:'/img/footer/dbddh.png?d='+new Date()*1
      },


      {
        path:'/game',
        name:this.$t("index.task") ,
        activeImg:'/img/footer/business-active.png?d='+new Date()*1,
        img:'/img/footer/business.png?d='+new Date()*1
      },

      {
        path:'/activity',
        name:this.$t("foorter.event") ,
        activeImg:'/img/footer/dbhd-active.png?d='+new Date()*1,
        img:'/img/footer/dbhd.png?d='+new Date()*1
      },
    
      {
        path:'/Mine',
        name:this.$t("foorter.my") ,
        activeImg:'/img/footer/dbwd-active.png?d='+new Date()*1,
        img:'/img/footer/dbwd.png?d='+new Date()*1
      }
    ]
    };
  },
  methods: {},
  watch: {
    $route(to) {
      console.log(to.name)
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } 
      // else if (to.name == "nHomeList") {
      //   this.active = 1;
      //   this.show = true;
      // } 
      // else if (to.name == "choose") {
      //   this.active = 2;
      //   this.show = true;
      // } else if (to.name == "video") {
      //   this.active = 3;
      //   this.show = true;
      // } 
      
      else if (to.name == "mine") {
        this.active = 4;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } 
    // else if (this.$route.name == "nHomeList") {
    //   this.active = 1;
    //   this.show = true;
    // }
    //  else if (this.$route.name == "choose") {
    //   this.active = 2;
    //   this.show = true;
    // } else if (this.$route.name == "video") {
    //   this.active = 3;
    //   this.show = true;
    // } 
    else if (this.$route.name == "mine") {
      this.active = 4;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 50px;
.van-tabbar {
  height: @tabbar-height;
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 26px;
}
.tui {
  width: 5rem;
  height: 5rem!important;
  margin-top: -7.333vw;
  background-color: white;
  border-radius: 50%;
  border: 10px solid white;
  z-index: 10;
}
[class*="van-hairline"]::after {
  border: none !important;
}
.foorterTxt{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  font-size: 22px;
}
</style>
